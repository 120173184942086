<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-right">
            <router-link
              :to="{ name: 'orden-trabajo-index' }"
            >
              <button class="btn btn-white border border-light mr-2 px-4 py-1">
                Regresar
              </button>
            </router-link>
            <button
              class="btn btn-success mr-2 px-4 py-1"
            >
              Guardar
            </button>
            <button
              class="btn btn-success px-4 py-1"
            >
              Finalizar
            </button>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-1 text-right">
                <label><b>Paciente:</b></label>
              </div>
              <div class="col-sm-2 pl-0">
                Jorge Ledezma
              </div>
              <div class="offset-sm-7 col-sm-2 text-right">
                <b>Orden N° 22/23</b>
              </div>
            </div>
            <div class="row border-bottom border-warning">
              <div class="col-sm-1 text-right">
                <label><b>Servicio:</b></label>
              </div>
              <div class="col-sm-2 pl-0">
                Evaluación de Fonoaudiología
              </div>
              <div class="offset-sm-7 col-sm-2 text-right">
                (VS: 233/23)
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 text-right">
                <button class="btn btn-orange text-white">2: VERTIGO ADAPTADO</button>
              </div>
            </div>
            <div class="row mb-4 pb-2 border-bottom border-warning">
              <div class="col-sm-5 row">
                <div class="col-sm-2 pr-0 text-right">
                  <label class="col-form-label">
                    Fecha:
                  </label>
                </div>
                <div class="col-sm-4">
                  <date-picker
                    class="w-100"
                    v-model="fecha"
                    lang="es"
                    format="DD/MM/YYYY"
                    type="date"
                    value-type="date"
                  ></date-picker>
                </div>
                <div class="col-sm-2 px-0 text-right">
                  <label class="col-form-label">
                    Hora Inicio:
                  </label>
                </div>
                <div class="col-sm-3">
                  <input type="time" class="form-control">
                </div>
              </div>
            </div>

            <div class="row mx-0">
              <div class="col-sm-8 text-center">
                <h4><b>Cuestionario</b></h4>
              </div>
              <div class="col-sm-1 text-center">
                <h4><b>Tipo</b></h4>
              </div>
              <div class="col-sm-1 text-center">
                <h4><b>No</b></h4>
              </div>
              <div class="col-sm-1 px-0 text-center">
                <h4><b>A Veces</b></h4>
              </div>
              <div class="col-sm-1 text-center">
                <h4><b>Si</b></h4>
              </div>
            </div>

            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                1. El mirar hacia arriba ¿incrementa su problema?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                Fi
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                2. Debido a su problema ¿se siente usted frustrado (a)?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                E
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                3. Debido a su problema ¿restringe usted sus viajes de negocios o placer?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                F
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                4. El caminar por el pasillo de un supermercado ¿incrementa su problema?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                Fi
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                5. Debido a su problema ¿tiene usted dificultad de acostarse o levantarse de la cama?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                F
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                6. ¿Su problema restringe significativamente su participación en actividades sociales tales como salir a cenar, ir al cine o ir a fiestas?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                F
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                7. Debido a su problema ¿tiene usted dificultad para leer?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                F
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                8. El realizar actividades más complejas como deportes o tareas domésticas (barrer o guardar los platos), ¿incrementa sus problemas?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                Fi
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                9. Debido a su problema ¿tiene miedo de dejar su casa sin tener a alguien que le acompañe?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                E
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                10. Debido a su problema, ¿se ha sentido usted desconcertado(a) frente a los otros?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                E
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                11. Los movimientos rápidos de su cabeza ¿incrementan su problema?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                Fi
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                12. Debido a su problema ¿evita usted las alturas?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                F
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                13. Al levantarse de la cama, ¿se incrementa su problema?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                Fi
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                14. Debido a su problema ¿es difícil para usted realizar trabajos domésticos o de jardinería?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                F
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                15. Debido a su problema ¿tiene usted miedo de que la gente piense que está ebrio(a)?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                E
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                16. Debido a su problema, ¿es difícil para usted caminar solo?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                F
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                17. Caminar sobre una banqueta ¿incrementa su problema?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                Fi
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                18. Debido a su problema ¿es difícil para usted concentrarse?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                E
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                19. Debido a su problema ¿es difícil para usted caminar dentro de su casa en la oscuridad?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                F
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                20. Debido a su problema ¿tiene miedo de estar solo(a) en casa?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                E
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                21. Debido a su problema ¿se siente incapacitado(a)?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                E
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                22. Su problema ¿ha generado dificultades en sus relaciones con miembros de su familia o amigos?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                E
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                23. Debido a su problema ¿se siente usted deprimido(a)?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                E
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-8 py-1">
                24. Su problema ¿interfiere con su trabajo o con sus responsabilidades de familia?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                F
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>
            <div class="row mx-0 custom-bg-gray">
              <div class="col-sm-8 py-1">
                25. Al levantarse ¿se incrementa su problema?
              </div>
              <div class="col-sm-1 py-1 text-center border-cell-tipo">
                Fi
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 px-0 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
              <div class="col-sm-1 py-1 d-flex justify-content-center">
                <div class="form-check form-check-inline mr-0">
                  <input type="checkbox" style="width: 16px;height: 16px">
                </div>
              </div>
            </div>

            <div class="row mx-0">
              <div class="offset-sm-8 col-sm-1 pr-0 text-right">
                <h4><b>Valor:</b></h4>
              </div>
              <div class="col-sm-1 text-center">
                <h4><b>0</b></h4>
              </div>
              <div class="col-sm-1 px-0 text-center">
                <h4><b>2</b></h4>
              </div>
              <div class="col-sm-1 text-center">
                <h4><b>4</b></h4>
              </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-sm-9 py-2 text-center bg-orange">
                <h4 class="mb-0"><b class="text-white">INTERPRETACIÓN DE RESULTADOS</b></h4>
              </div>
              <div class="col-sm-3 py-2 text-center">
                <h4 class="mb-0"><b>0/25</b></h4>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-9">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th width="34%" class="text-center border bg-light">ASPECTO</th>
                      <th width="33%" class="text-center border bg-light">PUNTUACIÓN</th>
                      <th width="33%" class="text-center border bg-light">INTERPRETACIÓN</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center border">EMOCIONAL</td>
                      <td class="text-center border">0-15</td>
                      <td class="text-center border">Ausencia de discapacidad</td>
                    </tr>
                    <tr>
                      <td class="text-center border">FUNCIONAL</td>
                      <td class="text-center border">0-14</td>
                      <td class="text-center border">Ausencia de discapacidad</td>
                    </tr>
                    <tr>
                      <td class="text-center border">FÍSICA</td>
                      <td class="text-center border">0-9</td>
                      <td class="text-center border">Ausencia de discapacidad</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: 'formulario2',
  components: {
    DatePicker,
  },
  data() {
    return {
      fecha: null,
    };
  },
};
</script>

<style scoped>
.custom-bg-gray {
  background: #eee;
}
.border-cell-tipo {
  border-left: 4px solid #bbb;
  border-right: 4px solid #bbb;
}
</style>
